/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_USER = 'AUTH_USER';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UNSET = 'USER_UNSET';

export function authCheck() {
  return {
    type: AUTH_CHECK,
  };
}

export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}

// export function authRefreshToken(payload) {
//   return {
//     type: AUTH_REFRESH_TOKEN,
//     payload,
//   };
// }
//
// export function authResetPassword() {
//   return {
//     type: AUTH_RESET_PASSWORD,
//   };
// }

export function authUser(payload) {
  return {
    type: AUTH_USER,
    payload,
  };
}
