import React from 'react';
import PropTypes from 'prop-types';

const Item = ({ visibility = true, ...props }) =>
  visibility ? (
    <li
      style={
        props.lineTop && { borderTop: '1px solid rgba(207, 205, 201, 0.5)' }
      }
      onClick={() => {
        props.onClick && props.onClick();
        props.handleClick();
      }}
    >
      {props.children}
    </li>
  ) : null;

export default Item;

Item.propTypes = {
  children: PropTypes.any,
  handleClick: PropTypes.any,
  lineTop: PropTypes.any,
  onClick: PropTypes.any,
};
