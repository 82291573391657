import React from 'react';
const PropTypes = require('prop-types');

const Button = ({ handleClick, ...props }) => (
  <button onClick={() => handleClick()} type="button">
    {props.children}
  </button>
);

export default Button;

Button.propTypes = {
  children: PropTypes.any,
  handleClick: PropTypes.any,
};
