import { ACTIVITIES_SUCCESS } from "../../Wikis/store/actions";


const initialState = {
    activities: [],
};
const filters2 = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ACTIVITIES_SUCCESS:
            return { ...state, activities: [...payload] };
        default:
            return state;
    }
};

export default filters2;
