import { combineReducers } from "redux";
import user from "./user";
import auth from "../../containers/auth/store/reducer";
import filters from "../../containers/Home/store/reducer";
import filters2 from "../../containers/Home/store/reducer2";
import allEntities from "../../containers/AllEntities/store/reducer";

const rootReducer = combineReducers({ auth, user, filters, filters2, allEntities });

export default rootReducer;
