import React from 'react';
import './index.scss';
import {Link} from 'react-router-dom';
import Logo from '../../../components/Logo';

function Footer(props) {
  return (<footer>
    <div>
      <div>
        <Link to="/">Home</Link>
        <Link to="/legal-privacy">Legal & Privacy</Link>
        <Link to="/support">Support</Link>
      </div>

      <div className="logo-container">
        <Logo width="180px" height="50px" />
      </div>
    </div>
  </footer>);
}


export default React.memo(Footer);
