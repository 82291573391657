import XLSX from "xlsx";

function toDegreesMinutesAndSeconds(coordinate) {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + " " + minutes + " " + seconds;
}

export function convertDMS(location) {
  const latitude = toDegreesMinutesAndSeconds(location);
  const latitudeCardinal = location >= 0 ? "N" : "S";

  return latitude + " " + latitudeCardinal;
}

export const ID = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 36)
  );
};

export function paginationHelper(start, total, length) {
  // eslint-disable-next-line
  if (start - length < 0) {
    return { start: 0, length };
  }
  if (total <= length || start == total - 1) {
    return { start: start - length, length };
  }
  return { start: start, length };
}
export function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
  // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  let arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  let CSV = "";
  // Set Report title in first row or line

  CSV += ReportTitle + "\r\n\n";

  // This condition will generate the Label/Header
  if (ShowLabel) {
    var row = "";

    // This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
      // Now convert each value to string and comma-seprated
      row += index + ",";
    }

    row = row.slice(0, -1);

    // append Label row with line break
    CSV += row + "\r\n";
  }

  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    var row = "";

    // 2nd loop will extract each column and convert it in string comma-seprated
    for (var index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    // add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV == "") {
    alert("Invalid data");
    return;
  }

  // Generate a file name
  let fileName = "";
  // this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  // Initialize file format you want csv or xls
  let uri = "data:text/csv;charset=utf-8,%EF%BB%BF" + CSV;

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  // this trick will generate a temp <a /> tag
  let link = document.createElement("a");
  link.href = uri;

  // set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  // this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export const exportToXlsx = (data, filename) => {
  /* convert state to workbook */
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, `${filename}.xlsx`);
};
export const downloadFile = (blob, fileName) => {
  const link = document.createElement("a");
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  window.addEventListener("focus", e => URL.revokeObjectURL(link.href), {
    once: true
  });
};
export function resizableTable() {
  setTimeout(function() {
    var tables = document.getElementsByTagName("table");
    for (var i = 0; i < tables.length; i++) {
      for (let item of tables[i].rows) {
        resizableGrid(tables[i], item);
      }
    }

    function resizableGrid(table, item) {
      var row = table.getElementsByTagName("tr")[1],
        cols = row ? item.children : undefined;

      if (!cols) return;

      // table.style.overflow = 'hidden';

      var tableHeight = table.offsetHeight;

      for (var i = 0; i < cols.length; i++) {
        var div = createDiv(tableHeight);
        cols[i].appendChild(div);
        cols[i].style.position = "relative";
        setListeners(div);
      }

      function setListeners(div) {
        var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

        div.addEventListener("mousedown", function(e) {
          curCol = e.target.parentElement;
          nxtCol = curCol.nextElementSibling;
          pageX = e.pageX;

          var padding = paddingDiff(curCol);

          curColWidth = curCol.offsetWidth - padding;
          if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
        });

        div.addEventListener("mouseover", function(e) {
          // e.target.style.borderRight = '2px solid #0000ff';
        });

        div.addEventListener("mouseout", function(e) {
          e.target.style.borderRight = "";
        });

        document.addEventListener("mousemove", function(e) {
          if (curCol) {
            var diffX = e.pageX - pageX;

            if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

            curCol.style.width = curColWidth + diffX + "px";
          }
        });

        document.addEventListener("mouseup", function(e) {
          curCol = undefined;
          nxtCol = undefined;
          pageX = undefined;
          nxtColWidth = undefined;
          curColWidth = undefined;
        });
      }

      function createDiv(height) {
        var div = document.createElement("div");
        div.style.top = 0;
        div.style.right = 0;
        div.style.width = "5px";
        div.style.position = "absolute";
        div.style.cursor = "col-resize";
        div.style.userSelect = "none";
        div.style.height = height + "px";
        return div;
      }

      function paddingDiff(col) {
        if (getStyleVal(col, "box-sizing") == "border-box") {
          return 0;
        }

        var padLeft = getStyleVal(col, "padding-left");
        var padRight = getStyleVal(col, "padding-right");
        return parseInt(padLeft) + parseInt(padRight);
      }

      function getStyleVal(elm, css) {
        return window.getComputedStyle(elm, null).getPropertyValue(css);
      }
    }
  }, 1000);
}
export function resizableTimeLineTable() {
    setTimeout(function() {
        var tables = document.getElementsByTagName("table");
        for (var i = 0; i < tables.length; i++) {
            for (let item of tables[i].rows) {
                resizableGrid(tables[i], item);
            }
        }

        function resizableGrid(table, item) {
            var row = table.getElementsByTagName("tr")[1],
                cols = row ? item.children : undefined;

            if (!cols) return;

            // table.style.overflow = 'hidden';

            var tableHeight = table.offsetHeight;

            for (var i = 0; i < cols.length; i++) {
                var div = createDiv(tableHeight);
                cols[i].appendChild(div);
                cols[i].style.position = "relative";
                removeListeners(div);
                setListeners(div);
            }

            function setListeners(div) {
                var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

                div.addEventListener("mousedown", function(e) {
                    curCol = e.target.parentElement;
                    nxtCol = curCol.nextElementSibling;
                    pageX = e.pageX;

                    var padding = paddingDiff(curCol);

                    curColWidth = curCol.offsetWidth - padding;
                    if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
                });

                // div.addEventListener("mouseover", function(e) {
                //     // e.target.style.borderRight = '2px solid #0000ff';
                // });

                div.addEventListener("mouseout", function(e) {
                    e.target.style.borderRight = "";
                });

                document.addEventListener("mousemove", function(e) {
                    if (curCol) {
                        var diffX = e.pageX - pageX;

                        if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

                        curCol.style.width = curColWidth + diffX + "px";
                    }
                });

                document.addEventListener("mouseup", function(e) {
                    curCol = undefined;
                    nxtCol = undefined;
                    pageX = undefined;
                    nxtColWidth = undefined;
                    curColWidth = undefined;
                });
            }
            function removeListeners(div) {
                var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

                div.removeEventListener("mousedown", function(e) {
                    curCol = e.target.parentElement;
                    nxtCol = curCol.nextElementSibling;
                    pageX = e.pageX;

                    var padding = paddingDiff(curCol);

                    curColWidth = curCol.offsetWidth - padding;
                    if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
                });

                // div.addEventListener("mouseover", function(e) {
                //     // e.target.style.borderRight = '2px solid #0000ff';
                // });

                div.removeEventListener("mouseout", function(e) {
                    e.target.style.borderRight = "";
                });

                document.removeEventListener("mousemove", function(e) {
                    if (curCol) {
                        var diffX = e.pageX - pageX;

                        if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

                        curCol.style.width = curColWidth + diffX + "px";
                    }
                });

                document.removeEventListener("mouseup", function(e) {
                    curCol = undefined;
                    nxtCol = undefined;
                    pageX = undefined;
                    nxtColWidth = undefined;
                    curColWidth = undefined;
                });
            }
            function createDiv(height) {
                var div = document.createElement("div");
                div.style.top = 0;
                div.style.right = 0;
                div.style.width = "5px";
                div.style.position = "absolute";
                div.style.cursor = "col-resize";
                div.style.userSelect = "none";
                div.style.height = height + "px";
                return div;
            }

            function paddingDiff(col) {
                if (getStyleVal(col, "box-sizing") == "border-box") {
                    return 0;
                }

                var padLeft = getStyleVal(col, "padding-left");
                var padRight = getStyleVal(col, "padding-right");
                return parseInt(padLeft) + parseInt(padRight);
            }

            function getStyleVal(elm, css) {
                return window.getComputedStyle(elm, null).getPropertyValue(css);
            }
        }
    }, 1000);
}
export function offset(el) {
  var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}
export function elementOffset(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top - scrollTop, left: rect.left + scrollLeft }
}
export function historyGoBack(uri) {
  if(document.referrer == ""){
    window.location.href= uri || "/"

  }else{
    window.history.back()
  }
}
