import React, { useState } from "react";
import { connect } from "react-redux";
import SearchIcon from "../Docments/icons/searchIcon.svg";
import { fullSearch } from "./api";
import { Link } from "react-router-dom";
import { TYPES } from "../../constants";
import { Loader } from "../LoadingIndicator/LoadingIndicator";
import TagInput from "../TagInput";

class SearchInput extends React.Component {
  render() {
    return (
      <input
        type="search"
        className={"SearchBar__input"}
        placeholder={"Search"}
        onChange={(e) => {
          const value = e.target.value;

          this.props.setValue(value);
          this.props.setLoader(true);
          if (value === "") {
            this.props.setResult({});
            this.props.setLoader(false);
            clearTimeout(this.searchTimer);
          } else {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
              this.props.search(value);
            }, 1000);
          }
        }}
      />
    );
  }
}
const SearchBar = ({ }) => {
  const [results, setResult] = useState({});
  const [value, setValue] = useState("");
  const [loader, setLoader] = useState(false);
  // useEffect(() => {
  //   (async () => {
  //     const {
  //       data: { documents, entities, learning_agenda }
  //     } = await fullSearch("ab");
  //     setResult({
  //       communities,
  //       thematics,
  //       documents,
  //       entities,
  //       "learning agendas": learning_agenda
  //     });
  //   })();
  // }, []);
  function remove_non_word(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    var PATTERN = /[^\x20\x2D0-9A-Z\x5Fa-z\xC0-\xD6\xD8-\xF6\xF8-\xFF]/g;

    return str.replace(PATTERN, "");
  }
  const search = async (query) => {
    const {
      data: {
        documents,
        entities,
        learning_agenda,
        thematic_communities,
        communities,
        trends,
      },
    } = await fullSearch(query);

    setResult({
      communities,
      thematics: thematic_communities,
      documents,
      entities,
      "learning agendas": learning_agenda,
      trends
    });
    setLoader(false);
  };
  const renderer = (results, category) => {
    if (results.length > 0)
      return (
        <ul className={"SearchBar__list"}>
          <li className={"SearchBar__list-title"}>{category}</li>
          {renderByType(results, category)}
        </ul>
      );
  };

  const renderByType = (results, category) => {
    return results.map((item) => {
      if (category === "communities") {
        return (
          <li>
            <Link to={`/community/${item.id}`} target={"_blank"}>
              {item.name}
            </Link>
          </li>
        );
      }
      if (category === "thematics") {
        return (
          <li>
            <Link to={`/thematic-wiki/${item.id}`} target={"_blank"}>
              {item.name || item.title}
            </Link>
          </li>
        );
      }
      if (category === "learning agendas") {
        return (
          <li>
            <Link to={`/learning-agenda`} target={"_blank"}>
              {item.name || item.title}
            </Link>
          </li>
        );
      }
      if (category === "entities") {
        return (
          <li>
            <img
              src={
                item.image && item.image.length > 3
                  ? item.image
                  : TYPES.filter(
                    (e) =>
                      e.name &&
                      item.type &&
                      e.name.toLowerCase() == item.type.toLowerCase()
                  )[0]
                    ? TYPES.filter(
                      (e) =>
                        e.name &&
                        item.type &&
                        e.name.toLowerCase() == item.type.toLowerCase()
                    )[0].icon
                    : null
              }
            />
            <Link to={`/entity/${item.id}`} target={"_blank"}>
              <span className={"header"}>{item.name || item.title}</span>
            </Link>
          </li>
        );
      }
      if (category === "trends") {
        return (
          <li>
            <Link to={`/trends?id=${item.id}`} target={"_blank"}>
              <span className={"header"}>{item.name || item.title}</span>
            </Link>
          </li>
        );
      }
      if (category === "documents") {
        return (
          <li>
            <Link to={`/document/${item.id}`} target={"_blank"}>
              <span className={"header"}>{item.name || item.title || item.place}</span>
            </Link>
          </li>
        );
      }
      return <li>{item.name || item.title || item.place}</li>;
    });
  };
  const map = () =>
    Object.keys(results).map((item) => renderer(results[item], item));

  const searchCount = Object.keys(results).reduce((acc, item) => {
    return acc + results[item].length;
  }, 0);
  return (
    <div className={"SearchBar"}>
      {/* <img src={SearchIcon} alt="search" className={"SearchBar__img"} /> */}
      {/*<SearchInput search={search} setValue={setValue} />*/}
      <TagInput
        onChangeEnd={search}
        setLoader={setLoader}
        setResult={setResult}
      />
      {searchCount ? (
        <div className={"SearchBar__result"}>{map()}</div>
      ) : loader ? (
        <div className={"SearchBar__result--small"}>
          <Loader />
        </div>
      ) : value.length > 0 ? (
        <div className={"SearchBar__result--small"}>
          <p>No result</p>
        </div>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  communities: state.filters.communities,
  thematics: state.filters.thematics,
});
export default connect(mapStateToProps)(SearchBar);
