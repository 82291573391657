export const COMMUNITIES_SUCCESS = 'COMMUNITIES_SUCCESS';
export const THEMATICS_SUCCESS = 'THEMATICS_SUCCESS';
export const ACTIVITIES_SUCCESS = 'ACTIVITIES_SUCCESS';
export const DOCUMENT_CATEGORIES_SUCCESS = 'DOCUMENT_CATEGORIES_SUCCESS';
export const DOCUMENT_AUTHORS_SUCCESS = 'DOCUMENT_AUTHORS_SUCCESS';
export const LEARNING_AGENDAS_SUCCESS = 'LEARNING_AGENDAS_SUCCESS';
export const ADD_NEW_ACTOR = 'ADD_NEW_ACTOR';

export const communities = payload => ({
  type: COMMUNITIES_SUCCESS,
  payload,
});
export const thematics = payload => ({
  type: THEMATICS_SUCCESS,
  payload,
});
export const activities = payload => ({
  type: ACTIVITIES_SUCCESS,
  payload,
});
export const documentCategories = payload => ({
  type: DOCUMENT_CATEGORIES_SUCCESS,
  payload,
});
export const documentAuthors = payload => ({
  type: DOCUMENT_AUTHORS_SUCCESS,
  payload,
});
export const learningAgendas = payload => ({
  type: LEARNING_AGENDAS_SUCCESS,
  payload,
});export const addNewActor = payload => ({
  type: ADD_NEW_ACTOR,
  payload,
});
