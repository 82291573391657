import Loadable from "react-loadable";
// import components
import LoadingComponent from "../components/LoadingIndicator";

const routes = [
  {
    path: "/",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Home"),
      loading: LoadingComponent
    })
  },
  {
    path: "/documents",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Home"),
      loading: LoadingComponent
    })
  },
  {
    path: "/article",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Article"),
      loading: LoadingComponent
    })
  },
  {
    path: "/wikis",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Wikis"),
      loading: LoadingComponent
    })
  },
  {
    path: "/library",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Library"),
      loading: LoadingComponent
    })
  },
  {
    path: "/login",
    exact: true,
    component: Loadable({
      loader: () => import("../containers/auth/Login"),
      loading: LoadingComponent
    })
  },
  {
    path: "/register",
    exact: false,
    component: Loadable({
      loader: () => import("../containers/auth/Register"),
      loading: LoadingComponent
    })
  },
  {
    path: "/password/reset",
    exact: true,
    component: Loadable({
      loader: () => import("../containers/auth/ResetPassword"),
      loading: LoadingComponent
    })
  },
  {
    path: "/password/recover",
    exact: true,
    component: Loadable({
      loader: () => import("../containers/auth/RecoverPassword"),
      loading: LoadingComponent
    })
  },
  {
    path: "/trends",
    exact: true,
    auth: true,
    allowed: ["admin", "contributor"],
    component: Loadable({
      loader: () => import("../containers/Events"),
      loading: LoadingComponent
    })
  },
  {
    path: "/learning-agenda",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/LearningAgenda"),
      loading: LoadingComponent
    })
  },
  {
    path: "/document/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Document"),
      loading: LoadingComponent
    })
  },
  {
    path: "/community/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Community"),
      loading: LoadingComponent
    })
  },
  {
    path: "/thematic-wiki/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/ThematicCommunity"),
      loading: LoadingComponent
    })
  },
  {
    path: "/activity/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Activity"),
      loading: LoadingComponent
    })
  },
  {
    path: "/entity/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Entity"),
      loading: LoadingComponent
    })
  },
  {
    path: "/main-partners",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/MainPartners"),
      loading: LoadingComponent
    })
  },
  {
    path: "/all-entities",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/AllEntities"),
      loading: LoadingComponent
    })
  },
  {
    path: "/support",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("../containers/Support"),
      loading: LoadingComponent
    })
  }
];

export default routes;
