import {
  USER_UPDATE,
  USER_UNSET,
  AUTH_LOGOUT,
  AUTH_USER,
  AUTH_LOGIN
} from "../../containers/auth/store/action";
import User from "../../models/User";
import { ID } from "../../utils/helpers";
const initialState = Object.assign({}, new User({}));

const user = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case AUTH_LOGIN:
      return updateUser(state, {
        ...payload.user,
        type: userTypes(payload.user.type),
        _ID: ID()
      });
    case AUTH_USER:
      return authUser(state, payload);
    case USER_UPDATE:
      return updateUser(state, payload);
    case AUTH_LOGOUT:
      return logout();
    case USER_UNSET:
      return unsetUser(state);
    default:
      return state;
  }
};
function userTypes(type) {
  switch (type) {
    case "1":
      return "admin";
    case "2":
      return "contributor";
    case "3":
      return "viewer";
    case "4":
      return "sbu";
    case "5":
      return "rsbu";
    case "6":
      return "adminLevel2";
    default:
      return "sbu";
  }
}
function updateUser(state, payload) {
  return {
    ...state,
    ...payload
  };
}

function unsetUser(state) {
  return {
    ...state,
    initialState
  };
}
function logout() {
  return {
    ...initialState
  };
}

function authUser(state, payload) {
  return {
    ...state,
    ...payload
  };
}

export default user;
