import * as React from "react";
import BitwisePopup from "./BitwisePopup";
import { BITWISE_AND, BITWISE_OR } from "../../constants";

const Keywords = ({ data, onClick, setBitwiseOperator }) => {
  return data.map((item, index) => {
    if (item === BITWISE_AND || item === BITWISE_OR) {
      return (
        <BitwisePopup
          key={index}
          setOperator={value => setBitwiseOperator({ index, value })}
        />
      );
    }
    return (
      <li
        className="TagInput__tag-list-item"
        key={index}
        onClick={() => onClick(index)}
      >
        {item}
      </li>
    );
  });
};

export default Keywords;
