import Loadable from 'react-loadable';
// import components
import LoadingComponent from '../components/LoadingIndicator';

const prefix = '/dashboard';
const params = {
  exact: true,
  auth: true,
};

const routes = [
  {
    path: `${prefix}/users`,
    component: Loadable({
      loader: () => import('../containers/admin/Users'),
      loading: LoadingComponent,
    }),
    ...params
  },
  {
    path: `${prefix}/profile`,
    component: Loadable({
      loader: () => import('../containers/admin/MyProfile'),
      loading: LoadingComponent,
    }),
    ...params
  },
  {
    path: `${prefix}/trends`,
    component: Loadable({
      loader: () => import('../containers/admin/Event'),
      loading: LoadingComponent,
    }),
    ...params
  }, {
    path: `${prefix}/locations`,
    component: Loadable({
      loader: () => import('../containers/admin/Locations'),
      loading: LoadingComponent,
    }),
    ...params
  },
  {
    path: `${prefix}/document-categories`,
    component: Loadable({
      loader: () => import('../containers/admin/DocumentCategories'),
      loading: LoadingComponent,
    }),
    ...params
  }, {
    path: `${prefix}/notifications`,
    component: Loadable({
      loader: () => import('../containers/admin/Notifications'),
      loading: LoadingComponent,
    }),
    ...params
  },
];

export default routes;
