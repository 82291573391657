// import libs
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Navigation from "../components/Navigation";
import Footer from "../containers/admin/Footer";

const displayName = "Private Layout";
const propTypes = {
  children: PropTypes.node.isRequired,
};

function PrivateLayout({ children }) {
  return (
    <Fragment >
      <div className="dashboard" style={{ position: "relative" }}>
        <Navigation />
        <main className="main-container">
          {children}
          <Footer />
        </main>
      </div>
    </Fragment>
  );
}

PrivateLayout.dispatch = displayName;
PrivateLayout.propTypes = propTypes;

export default PrivateLayout;
