import styled from 'styled-components';
import IndicatorStyles from './IndicatorStyles';

const Indicator = styled.div`
  margin:auto;
  position: relative;
  width: 64px;
  height: 64px;
  ${IndicatorStyles}
`;

export default Indicator;
