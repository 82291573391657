import React from 'react';
import LoaderContainer from './LoaderContainer';
import Indicator from './Indicatior';

const LoadingIndicator = () => (
    <LoaderContainer>
        <Loader/>
    </LoaderContainer>
);
export const Loader = () => <Indicator>
    <div/>
    <div/>
    <div/>
    <div/>
    <div/>
    <div/>
    <div/>
    <div/>
</Indicator>;
export default LoadingIndicator;
