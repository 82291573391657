import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Item from './item';
import Button from './Button';
import './index.scss';

class DropDown extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  static Item = Item;

  static Button = Button;

  renderButton = () =>
    React.cloneElement(this.props.children[0], {
      handleClick: this.handleClick,
    });

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderList = () =>
    this.props.children.map((item, index) =>
      index !== 0 && item
        ? React.cloneElement(item, {
          handleClick: this.handleClick,
          key: index,
        })
        : null,
    );

  render() {
    return (
      <div className={this.state.isOpen ? 'drop-down open' : 'drop-down'}>
        {this.renderButton()}
        <ul>{this.renderList()}</ul>
      </div>
    );
  }
}
DropDown.propTypes = {
  children: PropTypes.array,
};

export default DropDown;
