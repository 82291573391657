import * as React from "react";
import { BITWISE_AND, BITWISE_OR } from "../../constants";

const BitwisePopup = ({ visible, setOperator }) => {
  const [value, setValue] = React.useState("and");
  // if (!visible) {
  //   return null;
  // }
  return (
    <li className="TagInput__list-input--bitwise">
      <div className="BitwisePopup">
        {/*<span*/}
        {/*  className="BitwisePopup__operator"*/}
        {/*  onClick={() => setValue(value)}*/}
        {/*>*/}
        {/*  {value}*/}
        {/*  <span className="icon arrow" />*/}
        {/*</span>*/}
        {/*<div className={"BitwisePopup__drop-down"}>*/}
        {/*  <span className={"BitwisePopup__drop-down-item"}>and</span>*/}
        {/*  <span className={"BitwisePopup__drop-down-item"}>or</span>*/}
        {/*</div>*/}
        <select
          onChange={e => {
            const value = e.target.value;
            setValue(value);
            setOperator(value);
          }}
          value={value}
          className="BitwisePopup__select"
        >
          <option value={BITWISE_AND}>And</option>
          <option value={BITWISE_OR}>Or</option>
        </select>
        {/*/*/}
        {/*<span*/}
        {/*  className="BitwisePopup__operator"*/}
        {/*  onClick={() => setOperator("or")}*/}
        {/*>*/}
        {/*  or*/}
        {/*</span>*/}
      </div>
    </li>
  );
};

export default BitwisePopup;
