export const COMMUNITIES_SUCCESS = 'COMMUNITIES_SUCCESS';
export const THEMATICS_SUCCESS = 'THEMATICS_SUCCESS';
export const DOCUMENT_CATEGORIES_SUCCESS = 'DOCUMENT_CATEGORIES_SUCCESS';
export const DOCUMENT_AUTHORS_SUCCESS = 'DOCUMENT_AUTHORS_SUCCESS';
export const LEARNING_AGENDAS_SUCCESS = 'LEARNING_AGENDAS_SUCCESS';
export const ADD_NEW_ACTOR = 'ADD_NEW_ACTOR';
export const PARTNERS = 'PARTNERS';
export const TRENDS_FILTER = 'TRENDS_FILTER';
export const RESET_FILTER = 'RESET_FILTER';

export const communities = payload => ({
  type: COMMUNITIES_SUCCESS,
  payload,
});
export const thematics = payload => ({
  type: THEMATICS_SUCCESS,
  payload,
});
export const documentCategories = payload => ({
  type: DOCUMENT_CATEGORIES_SUCCESS,
  payload,
});
export const documentAuthors = payload => ({
  type: DOCUMENT_AUTHORS_SUCCESS,
  payload,
});
export const partners = payload => {
  return ({
    type: PARTNERS,
    payload,
  });
}
// export const partners = payload => ({
//   type: "static",
//   payload,
// })
export const learningAgendas = payload => ({
  type: LEARNING_AGENDAS_SUCCESS,
  payload,
}); export const addNewActor = payload => ({
  type: ADD_NEW_ACTOR,
  payload,
});

export const setFilter = (payload) => {
  console.log(payload, "setFilter")
  return ({
    type: TRENDS_FILTER,
    payload
  })
}

export const resetFilters = () => {
  return ({
    type: RESET_FILTER
  })
}