import {
    COMMUNITIES_SUCCESS,
    DOCUMENT_AUTHORS_SUCCESS,
    DOCUMENT_CATEGORIES_SUCCESS,
    LEARNING_AGENDAS_SUCCESS, THEMATICS_SUCCESS, PARTNERS, TRENDS_FILTER, RESET_FILTER
} from './actions';


const initialState = {
    communities: [],
    thematics: [],
    documentCategories: [],
    documentAuthors: [],
    learningAgendas: [],
    partners: [],
    trendsFilter: {},
    allPartners: []
};
const filters = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case COMMUNITIES_SUCCESS:
            return { ...state, communities: [...payload] };
        case THEMATICS_SUCCESS:
            return { ...state, thematics: [...payload] };
        case DOCUMENT_CATEGORIES_SUCCESS:
            return { ...state, documentCategories: [...payload] };
        case DOCUMENT_AUTHORS_SUCCESS:
            return { ...state, documentAuthors: [...payload] };
        case LEARNING_AGENDAS_SUCCESS:
            return { ...state, learningAgendas: [...payload] };
        case PARTNERS:
            return { ...state, partners: [...payload] };
        case TRENDS_FILTER:
            return { ...state, trendsFilter: { value: payload.name, innerValue: payload.id } };
        case RESET_FILTER:
            return { ...state, trendsFilter: {} };
        default:
            return state;
    }
};

export default filters;
