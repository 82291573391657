import {
    ALL_PARTNERS
} from './actions';

const initialState = [];
const allEntities = (state = initialState, { type, payload = [] }) => {
    console.log(type, payload, "//12\\")
    switch (type) {
        case ALL_PARTNERS:
            return payload;
        default:
            return state;
    }
};

export default allEntities;
