import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/index";

const middlewares = [thunk];

// if (process.env.NODE_ENV !== "production") {
//   middlewares.push(createLogger({}));
// }
const persistConfig = {
  key: "root",
  storage,
};
const compose = composeWithDevTools({ realtime: true, fport: 8097 });
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(onCompletion) {
  const enhancer = compose(applyMiddleware(...middlewares));

  const store = createStore(persistedReducer, enhancer);
  store.subscribe(() => console.log(store.getState(), "store"))
  const persistor = persistStore(store, [persistConfig, onCompletion]);
  return { store, persistor };
}
