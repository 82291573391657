import { BrowserRouter as Router } from "react-router-dom";
import React, { Component } from "react";
import moment from "moment-timezone";
import Provider from "react-redux/es/components/Provider";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./store/configureStore";
import Routs from "./routes";
import HTTP from "./utils/Http";
import { authCheck, authLogout } from "./containers/auth/store/action";
import SimpleBar from 'simplebar-react';
import { Font } from "canvg";

function none() { }
if (process.env.NODE_ENV === "production") {
  console.log = none;
  console.warn = none;
  console.error = none;
}
window.ga = none;

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  }
  else {
    begin += 2;
    var end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      ready: false,
      configureStore: configureStore(() => this.setState({ isLoading: true })),
    };
    moment.tz.setDefault("GMT");
  }

  componentWillMount() {
    const { store } = this.state.configureStore;
    HTTP.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.hasOwnProperty("response") && error.response.status === 401) {
          store.dispatch(authLogout());
        }
        return Promise.reject(error);
      }
    );
    if (getCookie("newBuild7") == null) {
      deleteAllCookies()
      HTTP.post("/logout").then((res) => {
        store.dispatch(authLogout());
      });
      document.cookie = "newBuild7=true";
    }
    const auth = setInterval(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (store.getState()._persist.rehydrated) {
        store.dispatch(authCheck());
        this.setState({ ready: true });
        clearInterval(auth);
        let a = moment();
        let b = moment(sessionStorage.getItem("last_activity"));
        if (a.diff(b) > 1000 * 60 * 60) {
          // alert('ss')
          store.dispatch(authLogout());
        }
      }
    });
    // store.dispatch(authCheck());
  }

  render() {

    const { store, persistor } = this.state.configureStore;
    if (this.state.isLoading) return null;
    return (
      <Router>
        <Provider store={store}>
          <PersistGate loading={false} persistor={persistor}>
            {/*<SimpleBar>*/}
            {this.state.ready && <Routs />}
            {/*</SimpleBar>*/}
          </PersistGate>
        </Provider>
      </Router>
    );
  }
}

export default App;
