import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import "./index.scss";
import App from "./App";
// import './simplebar.scss';
// import SheetJSApp from "./containers/Events/tessss";

Modal.setAppElement("#root");
ReactDOM.render(<App />, document.getElementById("root"));
// ReactDOM.render(  <SheetJSApp/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
