import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "./routes";
import PrivateRoute from "../middlewares/Private";
import PublicRoute from "../middlewares/Public";
import Layout from "../layout";

const Routes = () => (
  <Router>
    <Layout>
      <Switch>
        {routes.map((route, i) => {
          if (route.auth) {
            // eslint-disable-next-line react/no-array-index-key
            return <PrivateRoute key={i} {...route} />;
          }
          // eslint-disable-next-line react/no-array-index-key
          return <PublicRoute key={i} {...route} />;
        })}
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
