import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.jpeg';

const Logo = ({ width, height }) => (
  <Link to="/" className="proximity-logo-transparent">
    <img src={logo} alt="logo" style={{ width, height }} />
  </Link>
);
Logo.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
};
export default Logo;
