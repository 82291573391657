import * as React from "react";
import "./index.scss";
import Keywords from "./Keywords";
import { BITWISE_AND, BITWISE_OR, BITWISE_NAMES } from "../../constants";
const useDoublePress = () => {
  const delta = 500;
  const [lastKeypressTime, setLastKeypressTime] = React.useState(0);
  const checkDoublePress = (e, cb) => {
    let thisKeypressTime = new Date();
    if (thisKeypressTime - lastKeypressTime <= delta) {
      if (typeof cb === "function") {
        cb();
      } else {
        throw new Error("Call back function not found");
      }

      thisKeypressTime = 0;
    }
    setLastKeypressTime(thisKeypressTime);
  };

  return {
    checkDoublePress
  };
};

class SearchInput extends React.Component {
  constructor() {
    super();
    this.inputRef = React.createRef();
  }
  focus = () => this.inputRef.focus();

  get value() {
    return this.inputRef.value;
  }

  setEmptyValue = () => {
    this.inputRef.value = "";
    clearTimeout(this.searchTimer);
  };

  render() {
    return (
      <input
        ref={ref => (this.inputRef = ref)}
        type="text"
        className={"SearchBar__input"}
        placeholder={"Search"}
        onChange={e => {
          const value = e.target.value;
          this.props.setValue(value);
          this.props.setLoader(true);
          if (value === "") {
            this.props.setResult({});
            this.props.setLoader(false);
            clearTimeout(this.searchTimer);
          } else {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
              if (value.trim().length) {
                this.props.search(value.trim());
              }
            }, 1000);
          }
        }}
      />
    );
  }
}

const TagInput = ({ placeholder, onChangeEnd, setResult, setLoader }) => {
  const inputRef = React.useRef(null);
  const container = React.useRef(null);
  const { checkDoublePress } = useDoublePress();
  const [keywords, setKeywords] = React.useState([]);
  const [value, setValue] = React.useState("");

  /**
   * Memorized function handle space double press
   * @type {Function}
   */
  const handleKeyboard = React.useCallback(
    e => {
      if (e.code === "Space") {
        checkDoublePress(e, () => {
          e.preventDefault();
          if (value.trim().length) {
            setKeywords(
              keywords.concat(value.trim().replace(".", "")).concat(BITWISE_AND)
            );
            setValue("");
            inputRef.current.setEmptyValue();
          }
        });
      } else if (e.code === "Backspace" && !value.trim().length) {
        setKeywords(
          keywords.filter((item, index) => index < keywords.length - 2)
        );
      }
    },
    [keywords, value, checkDoublePress]
  );

  /**
   * Add event listener effect
   */
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyboard);
    return () => document.removeEventListener("keydown", handleKeyboard);
  }, [handleKeyboard]);

  /**
   * Effect to parent change value
   */
  React.useEffect(() => {
    if (keywords.length) {
      setTimeout(makeValue);
    }
    if (keywords.length === 0) {
      setResult({});
    }
    container.current.scrollLeft = container.current.scrollWidth;
  }, [keywords]);

  /**
   *  remove item from keywords
   * @param arr
   * @param index
   */
  const removeItem = (arr, index) =>
    setKeywords(keywords.filter((item, i) => i !== index));

  /**
   * focus input with reference when not clicked on select
   * @returns {*}
   */
  const focusInput = (e): void => {
    if (e.target.nodeName !== "SELECT") {
      inputRef.current.focus();
    }
  };

  /**
   *  set Bitwise operator to keywords array
   * @param operator
   */
  const setBitwiseOperator = ({ index, value }): void => {
    keywords[index] = value;
    setKeywords(keywords);
    makeValue();
  };
  /**
   *
   */
  const makeValue = (): void => {
    let res = [];
    keywords.forEach((item, index) => {
      if ([BITWISE_AND, BITWISE_OR].includes(item)) {
        res[index - 1] = {
          value: res[index - 1].value,
          operator: BITWISE_NAMES[item]
        };
      } else {
        res[index] = { value: item, operator: null };
      }
    });

    res = res.filter(item => item);

    if (inputRef.current.value) {
      res.push({ value: inputRef.current.value, operator: null });
    }

    if (res.length === 0) {
      setResult({});
      return;
    }
    onChangeEnd(res);

    // onChangeEnd({ value: keywords.join(" "), keywords });
  };
  return (
    <div className="TagInput" onClick={focusInput} ref={container}>
      <ul className="TagInput__tag-list">
        <Keywords
          data={keywords}
          onClick={index => { }}
          setBitwiseOperator={setBitwiseOperator}
        />

        <li className="TagInput__list-input">
          <SearchInput
            type="text"
            className="TagInput__input"
            ref={inputRef}
            placeholder={placeholder || "type keywords"}
            setValue={setValue}
            setLoader={setLoader}
            setResult={makeValue}
            search={makeValue}
            value={value}
          />
        </li>
      </ul>
    </div>
  );
};

export default TagInput;
