import React from 'react';
export default function(ComposedClass) {
  return  class AutoLogout extends React.Component {
    constructor(props) {
      super(props);
        this.state = {
        warningTime: 1000 * 60 * 60,
        signoutTime: 1000 * 60 * 60 ,
      };
    }

    componentDidMount() {
        if(this.props.user.type && this.props.user.type.includes('admin')){
            this.setState({signoutTime:604800000})
        }
        this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for (let i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      this.setTimeout();
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);

      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
      sessionStorage.setItem('last_activity', new Date());
    };

    warn = () => {
      // window.alert("You will be logged out automatically in 1 minute")
      console.warn('You will be logged out automatically in 1 minute.');
    };

    logout = () => {
      // Send a logout request to the API
      console.warn('Sending a logout request to the API...');
      localStorage.clear();
      this.destroy();
    };

    destroy = () => {
      // clear the session
      // browserHistory.push('/');
      window.location.assign('/');
    };

    render() {
      return  <ComposedClass {...this.props} />;
    }
  };
}
