export function checkPermission(role, priority) {
  let permissions = [];
  // admin///1
  // 'admin','contributor','viewer','sbu'
  switch (priority) {
    case 1:
      permissions = ['admin','adminLevel2'];
      break;
    case 2:
      permissions = ['admin', 'adminLevel2','contributor'];
      break;
    case 3:
      permissions = ['admin', 'adminLevel2','contributor','viewer'];
      break;
    case 4:
      permissions = ['admin','adminLevel2' ,'contributor','rsbu'];
      break;
    case 5: permissions = ['admin'];
      break;
    default:
      permissions = ['sbu'];
      break;
  }
  return permissions.includes(role);
}
