import axios from "axios/index";
// const API_URL =
//   process.env.NODE_ENV === 'test'
//     ? process.env.BASE_URL ||
//     : `/api/`;
// export const APP_URL = "http://35.238.242.1/backend";
// export const APP_URL = "http://localhost/backend/index.php"
// export const APP_URL = "https://abcexperimentlab.com/backend/index.php"
// export const APP_URL_LIBRARY = "https://abcexperimentlab.com/backend"
export const APP_URL = "http://kms.proximityinternational.com/backend/index.php"
export const APP_URL_LIBRARY = "http://kms.proximityinternational.com/backend"
// export const APP_URL = "https://kat.libyati.com/backend";
axios.defaults.baseURL = `${APP_URL}/api`;
axios.defaults.headers.common.Accept = "application/json";
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default axios;
