import Airline from "./icons/Airline.png";
import ArmedCoalition from "./icons/Armed Coalition.png";
import ArmedGroupMilitia from "./icons/Armed group  militia.png";
import Bank from "./icons/Bank.png";
import CriticalInfrastructure from "./icons/Critical infrastructure.png";
import ForeignGovernment from "./icons/Foreign government.png";
import ForeignMilitaryActor from "./icons/Foreign military actor.png";
import GeographicalBlock from "./icons/Geographical block.png";
import Hospital from "./icons/Hospital.png";
import IllegalMigrationDetentionCenter from "./icons/Illegal migration detention center.png";
import InternationalCommunity from "./icons/International community.png";
import InternationalNGO from "./icons/International NGO.png";
import InternationalOrganization from "./icons/International organization.png";
import LibyanCSO from "./icons/Libyan CSO.png";
import LibyanNGO from "./icons/Libyan NGO.png";
import Media from "./icons/Media.png";
import MigrationAndSmugglersNetwork from "./icons/Migration and smugglers network.png";
import Ministry from "./icons/Ministry.png";
import MunicipalCouncil from "./icons/Municipal council.png";
import MunicipalInstitution from "./icons/Municipal institution.png";
import Municipality from "./icons/Municipality.png";
import OilCompany from "./icons/Oil company.png";
import Person from "./icons/Person.png";
import PoliticalForceParty from "./icons/Political force party.png";
import Prison from "./icons/Prison.png";
import PrivateCompany from "./icons/Private company.png";
import PublicBodyAuthority from "./icons/Public body authority.png";
import ReligiousGroupForce from "./icons/Religious group force.png";
import ResidentialComplex from "./icons/Residential complex.png";
import SocialComponentForce from "./icons/Social component force.png";
import StateInstitution from "./icons/State institution.png";
import StateSecurityApparatus from "./icons/State security apparatus.png";
import StateOwnedCompany from "./icons/State-owned company.png";
import TradeUnion from "./icons/Trade union.png";
import TribalCouncil from "./icons/Tribal council.png";
import Tribe from "./icons/Tribe.png";
import University from "./icons/University.png";
export const SOCKET_URI = "https://kat.libyati.com"
export const TYPES = [
  { name: "Airline", icon: Airline },
  { name: "Armed coalition", icon: ArmedCoalition },
  { name: "Armed group / militia", icon: ArmedGroupMilitia },
  { name: "Bank", icon: Bank },
  { name: "Critical Infrastructure", icon: CriticalInfrastructure },
  { name: "Foreign government", icon: ForeignGovernment },
  { name: "Foreign military actor", icon: ForeignMilitaryActor },
  { name: "Geographical block", icon: GeographicalBlock },
  { name: "Hospital", icon: Hospital },
  {
    name: "Illegal migration detention center",
    icon: IllegalMigrationDetentionCenter
  },
  { name: "International community", icon: InternationalCommunity },
  { name: "International NGO", icon: InternationalNGO },
  { name: "International Organization", icon: InternationalOrganization },
  { name: "Libyan CSO", icon: LibyanCSO },
  { name: "Libyan NGO", icon: LibyanNGO },
  { name: "Media", icon: Media },
  {
    name: "Migration and smugglers network",
    icon: MigrationAndSmugglersNetwork
  },
  { name: "Ministry", icon: Ministry },
  { name: "Municipal council", icon: MunicipalCouncil },
  { name: "Municipal institution", icon: MunicipalInstitution },
  { name: "Municipality", icon: Municipality },
  { name: "Oil company", icon: OilCompany },
  { name: "Person", icon: Person },
  { name: "Political force / party", icon: PoliticalForceParty },
  { name: "Prison", icon: Prison },
  { name: "Private company", icon: PrivateCompany },
  { name: "Public body / authority", icon: PublicBodyAuthority },
  { name: "Religious group / force", icon: ReligiousGroupForce },
  { name: "Residential complex", icon: ResidentialComplex },
  { name: "Social component / force", icon: SocialComponentForce },
  { name: "State Institution", icon: StateInstitution },
  { name: "State security apparatus", icon: StateSecurityApparatus },
  { name: "State-owned company", icon: StateOwnedCompany },
  { name: "Trade union", icon: TradeUnion },
  { name: "Tribal council", icon: TribalCouncil },
  { name: "Tribe", icon: Tribe },
  { name: "University", icon: University }
];

export const ROLE_IN_SOCIETY = [
  { name: "Civilian ", id: 1 },
  { name: "Civil Society", id: 2 },
  { name: "Elites/Decision-makers", id: 3 },
  { name: "Government Institution", id: 4 },
  { name: "Formal Armed Actors", id: 5 },
  { name: "Informal Armed Actors", id: 6 }
];

export const BITWISE_AND = "BITWISE_AND";
export const BITWISE_OR = "BITWISE_OR";
export const BITWISE_NAMES = {
  [BITWISE_AND]: "and",
  [BITWISE_OR]: "or"
};
