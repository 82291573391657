import Http from "../../utils/Http";
import * as authActions from "./store/action";
import Transformer from "../../utils/Transformer";

/**
 * fetch the current logged in user
 *
 * @returns {function(*)}
 */
export function fetchUser() {
  return (dispatch) =>
    Http.post("auth/user").then((res) => {
      const data = Transformer.fetch(res.data.user);
      dispatch(authActions.authUser(data));
    });
}

/**
 * login user
 *
 * @param credentials
 * @returns {function(*)}
 */
export function login(credentials) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("/login", credentials)
        .then((res) => {
          // localStorage.clear();
          const data = Transformer.fetch(res.data);

          return resolve(data);
        })
        .catch((err) => {
          console.error(err.response);
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (statusCode === 422) {
            const resetErrors = {
              errors: err.response.data.errors,
              replace: false,
              searchStr: "",
              replaceStr: "",
            };
            data.error = Transformer.resetValidationFields(resetErrors);
          } else if (statusCode === 401) {
            data.error = err.response.data.message;
          }
          reject(err);
        });
    });
}

export const recoverPassword = (credentials) =>
  Http.post("/reset_password", credentials)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => err.response);

export function register(credentials) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("register", Transformer.send(credentials))
        .then((res) => {
          const data = Transformer.fetch(res.data);
          return resolve(data);
        })
        .catch((err) => {
          // const statusCode = err.response.status;
          // const data = {
          //   error: null,
          //   statusCode,
          // };
          //
          // if (statusCode === 422) {
          //   const resetErrors = {
          //     errors: err.response.data.errors,
          //     replace: false,
          //     searchStr: '',
          //     replaceStr: '',
          //   };
          //   data.error = Transformer.resetValidationFields(resetErrors);
          // } else if (statusCode === 401) {
          //   data.error = err.response.data.message;
          // }
          return reject(err);
        });
    });
}
export function otp(credentials) {
  return new Promise((resolve, reject) => {
    Http.post("otp", Transformer.send(credentials))
      .then((res) => {
        const data = Transformer.fetch(res.data);
        return resolve(data);
      })
      .catch((err) => {
        // const statusCode = err.response.status;
        // const data = {
        //   error: null,
        //   statusCode,
        // };
        //
        // if (statusCode === 422) {
        //   const resetErrors = {
        //     errors: err.response.data.errors,
        //     replace: false,
        //     searchStr: '',
        //     replaceStr: '',
        //   };
        //   data.error = Transformer.resetValidationFields(resetErrors);
        // } else if (statusCode === 401) {
        //   data.error = err.response.data.message;
        // }
        return reject(err);
      });
  });
}

/**
 * logout user
 *
 * @returns {function(*)}
 */
export function logout() {
  return (dispatch) =>
    // Http.post('auth/logout')
    //   .then(() => {
    dispatch(authActions.authLogout());
  // })
  // .catch(err => {
  // });
}
export const resetPassword = (data) =>
  Http.post("/check_reset_password", Transformer.send(data));
