import React, { Component } from "react";
import "./index.scss";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Logo from "../../assets/images/logo.jpeg";
import SettingIcon from "./icons/Oval 2.svg";
import { logout } from "../../containers/auth/service";
import DropDown from "../DropDown";
import { checkPermission } from "../../utils/permissions";
import SearchBar from "./SearchBar";

class Navigation extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { user, location, logout: logout1 } = this.props;
    const { pathname } = location;
    return (
      <header className="header">
        <div className="main-container">
          <NavLink to="/" className="knowledge-analysis-logo" >
            <img src={Logo} alt="proximity" />
          </NavLink>
          <div className="nav">
            <SearchBar />
            <ul className="nav-list">
              <li
                className={
                  pathname === "/" ? "nav-list-item active" : "nav-list-item"
                }
              >
                <NavLink to="/">Home</NavLink>
              </li>
              <li
                className={
                  pathname === "/wikis" ? "nav-list-item active" : "nav-list-item"
                }
              >
                <NavLink to="/wikis">Wikis</NavLink>
              </li>
              <li
                className={
                  pathname === "/documents"
                    ? "nav-list-item active"
                    : "nav-list-item"
                }
              >
                <NavLink to="/documents" onClick={(e) => {
                  const section = document.querySelector('#documents-section')
                  if (section) {
                    e.preventDefault()
                    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }
                }}>Documents</NavLink>
              </li>
              <li
                className={
                  pathname === "/trends"
                    ? "nav-list-item active"
                    : "nav-list-item"
                }
              >
                <NavLink to="/trends">Logs</NavLink>
              </li>
              <li
                className={
                  pathname === "/learning-agenda"
                    ? "nav-list-item active"
                    : "nav-list-item"
                }
              >
                <NavLink to="/learning-agenda">Learning Agenda</NavLink>
              </li>
            </ul>
          </div>
          <div className="settings">
            <DropDown>
              <DropDown.Button>
                <img src={SettingIcon} alt="settig-icon" />
              </DropDown.Button>
              <DropDown.Item>
                <Link to="/dashboard/profile">My Profile</Link>
              </DropDown.Item>
              <DropDown.Item>
                <Link to="/dashboard/notifications">Notifications</Link>
              </DropDown.Item>
              {checkPermission(this.props.user.type, 5) ? (
                <DropDown.Item visibility={checkPermission(user.type, 1)}>
                  <Link to="/dashboard/users">Users</Link>
                </DropDown.Item>
              ) : null}
              <DropDown.Item visibility={checkPermission(user.type, 1)}>
                <Link to="/dashboard/trends">Logs</Link>
              </DropDown.Item>
              <DropDown.Item visibility={checkPermission(user.type, 1)}>
                <Link to="/dashboard/locations">Communities</Link>
              </DropDown.Item>
              <DropDown.Item visibility={checkPermission(user.type, 1)}>
                <Link to="/dashboard/document-categories"> Documents</Link>
              </DropDown.Item>
              <DropDown.Item lineTop onClick={() => logout1()}>
                <span>Log Out</span>
              </DropDown.Item>
            </DropDown>
          </div>
        </div>
      </header>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state,
  user: state.user,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);

Navigation.propTypes = {
  location: PropTypes.object,
  logout: PropTypes.func,
};
